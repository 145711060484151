import axios from 'axios'
var base = 'https://murmuring-temple-63807.herokuapp.com/https://corn-cache1.herokuapp.com'



var base3 = `https://corn-cache1.herokuapp.com`
var xbt = base + '/XBTUSD'
async function get_tf(tf){
  let url = xbt + '/'+tf
  console.log(`sending request...`)
  let res = await axios(url)

  return res.data
}
export default {
  get_tf
}
// var  m1 = `${base}/${base3}/XBTUSD/m1`
// var m1 = 'https://murmuring-temple-63807.herokuapp.com/https://corn-cache1.herokuapp.com/XBTUSD/m1