<template>
  <!-- TradingVueJs 101 (example from 'Getting Started' ) -->

  <div>
    <div class="menutop">
      <select>
        <option>1m</option>
        <option>3m</option>
        <option>5m</option>
        <option>15m</option>
        <option>30m</option>
        <option>45m</option>
        <option>1h</option>
        <option>2h</option>
        <option>3h</option>
        <option>4h</option>
        <option>12h</option>
        <option>D</option>
        <option>W</option>
        <option>D</option>
      </select>
    </div>

    <trading-vue
      ref="tVue"
      :data="chart"
      :ext="ext"
      :width="this.width"
      :height="this.height"
      :color-back="colors.colorBack"
      :color-grid="colors.colorGrid"
      :color-text="colors.colorText"
      title-txt="elevate"
      :toolbar="true"
      :chart-config="{TB_ICON_BRI: 1.25}"
    ></trading-vue>
  </div>
</template>

<script>
// alert(3);
import { TradingVue, DataCube } from "trading-vue-js";
import XP from 'tvjs-xp'


// import Data from "../data/data.json";
import corncache from "@/services/corncache";

import m1data from "@/data/1m.json";
import m1data2 from "@/data/data.json";
export default {
  name: "app",
  components: { TradingVue },
  methods: {
    onResize(event) {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    async runInit() {
      let data = await corncache.get_tf("m5");
      // this.chart.chart.data = data;
      this.chart.data.chart.data = data
      // console.log(data);
      console.log(`updated data`)
    },
    async runInitDev() {
      this.setTitle();
      await this.runInit();

      this.$refs.tVue.resetChart();
      setInterval(this.runInit, 2000);
    },
    setTitle() {
      window.title = "wtf";
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.runInitDev();
    // this.chart.chart.data = m1data;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    tfchange() {

    }

  },
  data() {

    return {
      ext: Object.values(XP),
      chart: new DataCube({
        chart: { type: "Candles", data: m1data }, onchart: [], offchart: []
      }),
      // chart: {
      //   chart: {
      //     type: "Candles",
      //     data: m1data,
      //     // [1553965200000, 4152, 4150.1, 4146, 4150, 74.03849722],
      //   },
      // },
      width: window.innerWidth / 1.0099,
      // height: window.innerHeight / 1.1,
      // width: window.innerWidth / 1,
      height: window.innerHeight / 1.1,
      colors: {
        // colorBack: "#fff",
        // colorGrid: "#eee",
        // colorText: "#333",
      },
    };
  },
};
</script>
<style scoped>
.menutop {
}
</style>
